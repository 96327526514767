.c-youtube {
  margin-block: $unit-large;
  text-align:   center;

  svg {
    fill: $color-youtube;
  }

  a {
    display:       inline-block;
    margin-inline: auto;
    color:         white;
  }

  &:hover {
    a {
      color:           $color-youtube;
      text-decoration: none;
    }

    .c-youtube__wrapper {
      background-color: $color-light;
    }
  }
}


.c-youtube__wrapper {
  display:          flex;
  justify-content:  center;
  align-items:      center;
  gap:              .5em;
  padding:          $unit $unit-large;
  border-radius:    $unit-large;
  background-color: $color-medium;
  font-size:        1.25rem;
  transition:       background-color 150ms linear;
}


.cookie {
  margin-bottom:  1rem;
  padding-bottom: 1rem;
  border-bottom:  1px solid $color-light;

  &.cookie--last {
    border-bottom: none;
  }
}


.cookie__wrap {
  //display: grid;
  //grid-template-columns: 1fr 2fr 1fr;
  //grid-column-gap: 1rem;
}


.cookie__name {
  font-weight: 700;
}

.cookie__link {
  display:       block;
  margin-bottom: 1rem;
  font-weight:   400;
}


@include mq($from: tablet) {
  .cookie__wrap {
    display:               grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-column-gap:       1rem;
  }
  .cookie__valid {
    text-align: right;
  }
}


.cc-window{border-top:2px solid #dcdcdc!important;padding:1rem!important;transition:none!important;}
.cc-message,.cc-btn{font-size:.9rem!important;line-height:1.25rem!important;}
.cc-link{padding:0 0 0 3px!important;}
.cc-deny{font-weight:400!important;font-size:.8rem!important;color:#6c6c6c!important}
.cc-btn:hover{text-decoration:none!important;}

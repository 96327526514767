.c-element-header {
  text-align: center;
  padding-bottom: $unit-tiny;
  //margin-bottom: $unit-tiny;
  //@extend %separator-bottom, %separator--light;
}

.c-element-header__heading {
  @include font-size(22px);
  font-weight: 700;
}

.c-element-header__gamename {
  display: block;
  padding-top: $unit-tiny;
  @include font-size(16px);
}

.c-element-header__gamename-link {
  font-weight: 400;
  color: #B8A99A;
  color: #DF64B4;
  color: $color-black;
}

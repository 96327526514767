///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.



// Standardise some UI treatments.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;


$global-border-width: 1px;
$global-border-style: solid;




$mq-breakpoints: (
                   mobile:  320px,
                   phablet:  500px,
                   tablet:  768px,
                   desktop: 980px,
                   wide:    1300px
                 );

//$mq-show-breakpoints: (mobile, tablet, desktop, wide);
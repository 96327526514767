.c-special {
  margin-top:       2rem;
  margin-bottom:    2rem;
  padding:          2rem;
  background-color: lightpink;
  border-radius:    11px;

  p:last-of-type {
    margin-bottom: 0;
  }
}
html {
  font-family: $font-text;
  font-weight: 300;
  //background-color: #f9f9f9;
  scroll-behavior: smooth;
}

::selection {
  background-color: $color-primary;
  color: #fff;
}

.e-text--strong {
  font-weight: 700;
}

.u-center {
  text-align: center;
}

.new {
  font-size: .75rem;
  font-weight:      700;
  letter-spacing: 2px;
  background-color: chocolate;
  color:            white;
  text-transform: uppercase;
  padding: .25rem .5rem;
  margin-right: .33rem;
}

.c-to-top {
  position: fixed;
  z-index:  5;
  bottom:   $unit-small;
  right:    $unit-small;
  svg {
    fill: rgba($color-medium, 0.6);
  }
}


@include mq($from: desktop) {
  .c-to-top {
    bottom:   $unit;
    right:    $unit;
  }
}

.c-element-list {
  list-style:  none;
  margin-left: 0;
  @extend %separator-basic--small;
}


.c-element-list__heading,
.c-element-list__divider {
  margin-inline: auto;
  margin-bottom: $unit;
  text-align:    center;
  @include font-size(20px);
}


.c-element-list__item {
  line-height:    3rem;
  padding-inline: 1rem;
}


.c-element-list__divider {
  margin-bottom: $unit;
  font-size:     2.5rem;
  font-weight:   bold;
  @extend %separator-basic--small;

  > span {
    padding-left: $unit-small;
    font-size:   1.25rem;
    font-weight: 400;
    color:       $color-medium;
    > span {
      font-weight: 700;
      color:       $color-dark;
    }
  }

  &:first-of-type {
    @extend %separator-none;
  }
}

.c-faqs-heading {
  margin-bottom: $unit;
}

.c-faqs-jumplink{
  font-weight: 700;
  display: block;
  padding-bottom: .75rem;
}

.c-faqs-question {
  line-height:   1.45rem;
  margin-bottom: $unit-tiny;
  @extend %separator-top, %separator-top--padding-small, %separator--light;
}


.c-faqs-answer {
  strong {
    font-weight: 700;
  }
}

.c-icons {
  text-align: center;
}


.c-icons__heading {
  display:        block;
  margin-bottom:  $unit-small;
  font-size:      1rem;
  color:          $color-medium;
  text-transform: uppercase;
}

.c-icon__item {
  line-height: 1;
}

.c-icon__item + .c-icon__item {
  margin-left: $unit;
}


.c-icon {
  width: auto;
  height: 42px;

  &:hover {
    filter: saturate(2);
  }
}

.c-icon-1x1 {
  aspect-ratio: 1/1;
}

.c-icon-4x3 {
  aspect-ratio: 4/3;
}


.c-icon--twitter {
  fill: #76a9ea;
}


.c-icon--facebook {
  fill: #475993;
}


.c-icon--whatsapp {
  fill: #7ad06d;
}


.c-icon--email {
  fill: #422;
}

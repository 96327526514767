.c-jumplinks-wrapper {
  text-align:    center;
  margin-left:   auto;
  margin-right:  auto;
  margin-bottom: $unit-small;
}

.c-jumplinks {
  width:           100%;
  margin-left:     auto;
  margin-right:    auto;
  display:         flex;
  flex-wrap:       wrap;
  justify-content: center;
}

.c-jumplinks__new {
  font-weight: 700;
  padding: 0 calc($unit/4);
}

.c-jumplinks__heading {
  margin-left:   auto;
  margin-right:  auto;
  margin-bottom: $unit-tiny;
  text-align:    center;
  color:         $color-dark;
  @include font-size(16px);
}

.c-jumplinks__item {
  margin:           .15rem;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  width:            43px;
  height:           43px;
  background-color: $color-light;

  &:hover,
  &:focus {
    background-color: $color-white;
    outline:          1px solid $color-medium;
  }

  a {
    display: inline-block;
    padding: .5rem 1rem;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}


//@include mq($from: tablet) {
//  .c-jumplinks {
//    //width: 80%;
//  }
//}
//
//
//@include mq($from: desktop) {
//  .c-jumplinks {
//    //width: 90%;
//  }
//  .c-jumplinks__item {
//    //padding: 0 .25rem;
//  }
//}

.c-game-description {
  margin-bottom:$unit-large;
}
.c-game-description strong {
  font-weight: 700;
}

.c-game-description__heading{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $unit-tiny;
  text-align: center;
  color: $color-dark;
  @include font-size(16px);
}

.c-element__icon-wrapper {
  margin:           $unit auto;
  padding:          $unit-small $unit;
  text-align:       center;
  width:            240px;
  background-color: #f0f0f0;
  border-radius:    10%;
  display: block;
}


.c-element__icon {
  padding: $unit-small;
}
//
// Base colors
//
$color-white: #fcfcfc;
$color-light: #ececec;
$color-medium: #9c9c9c;
$color-dark: #5c5c5c;
$color-black: #3c3c3c;

//
// Primary color values
//
//$color-primary: #c145b4;
//$color-primary: #76357e;
$color-primary: #843179;
//$color-primary: #AE6DAB;

$color-primary-hover: lighten($color-primary, 10%);
$color-primary-visited: desaturate($color-primary, 10%);
$color-primary-buttontext: $color-white;

//
// Secondary color values
//
//$color-secondary: #343d7d;
//$color-secondary: #131840;
$color-secondary: #183b58;
$color-secondary: #7671A3; // Small Switch Palette
$color-secondary: #51424E; // Grey Friends
$color-secondary: #380028; // littlealchemy2.com

$color-secondary-hover: lighten($color-secondary, 10%);
$color-secondary-visited: desaturate($color-secondary, 10%);
$color-secondary-buttontext: $color-white;

$color-little2: #EFEFDC;
$color-little2: #DF64B4;

$color-youtube: rgb(255,0,0);
//$color-youtube: rgb(196, 48, 43);





.e-ad {
  display:          none;
  margin-left:      auto;
  margin-right:     auto;
  margin-bottom:    $unit;
  text-align:       center;
  width:            auto;
  @include clearfix();

  //background-color: lightsalmon;
}


//.ad-top-ins {
//  min-width: 320px;
//  max-width: 970px;
//  width:  100%;
//  height: 90px;
//}
//
//@include mq($from: phablet) {
//  .ad-top-ins {
//    width: 468px;
//    height: 90px;
//  }
//}
//
//@include mq($from: desktop) {
//  .ad-top-ins {
//    width: 728px;
//    height:     auto;
//  }
//  .e-ad--bottom {
//    position: fixed;
//    top:      100px;
//    width:    160px;
//  }
//}
//
//@include mq($from: wide) {
//  .e-ad--bottom {
//    width: 300px;
//  }
//}


.e-ad {
  min-height: 100px;

  &.e-ad--top > .ad-top-ins {
    width: 100%;
    height: 100px;
  }

  @include mq($from: phablet) {
    &.e-ad--top {
      max-width: 480px;
    }
  }

  @include mq($from: desktop) {
    &.e-ad--top {
      max-width: 560px;

      & > .ad-top-ins {
        min-height: 280px;
        height: auto;
      }
    }
    &.e-ad--bottom {
      position: fixed;
      top: 100px;
      width: 160px;
    }
  }

  @include mq($from: wide) {
    &.e-ad--bottom {
      width: 300px;
    }
  }

}



.c-combo {
  margin-bottom: $unit;
}

.c-combo-list {
  list-style-type: none;
  margin:          0;
  padding:         0;
}

.c-combo-list--all {
  margin-bottom: 2rem;
}

.c-combo-list--walkthrough {
  margin-bottom: 0;
}


.c-combo-list__head {
  color: $color-dark;
  text-align: left;
}


.c-combo-list__item {
  color:   $color-dark;
  padding: $unit-small $unit-tiny;
  @extend %separator-bottom, %separator--light;

  &:nth-child(odd) {
    background-color: $color-light;
  }
}

.combo-name {
  font-weight: 700;
  width: 33%;
}
.combo-name, .allcombos {
  color: #3c3c3c;
  vertical-align: top;
}

.c-combo-list__item-use {
  &:before {
    color:         $color-dark;
    padding-right: $unit-tiny;
    font-weight:   700;
  }
}


.c-combo-list__item-use--source {
  &:before {
    content: "+";
  }
}


.c-combo-list__item-use--target {
  &:before {
    content: "=";
  }
}



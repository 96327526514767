.c-filter-wrapper {
  display:          flex;
  flex-flow:        column nowrap;
  margin-bottom:    $unit;
  padding:          $unit-small;
  background-color: $color-light;
  outline:          1px solid $color-medium;
}


.c-filter + .c-filter {
  margin-top: $unit;
}



.c-filter__heading {
  font-weight: 700;
}



.c-filter__count,
.c-filter__info {
  font-size:   1rem;
  font-weight: 400;
}

.c-filter__count{
  margin: $unit 0 0 calc($unit/2);
}

.c-filter__count-value {
  font-weight: 700;
}


.c-filter__query {
  padding: 3px;
}



.c-filter__list {
  margin-bottom: 0;
}



.c-filter__item {
  display:     flex;
  align-items: center;
  accent-color: $color-primary;
  & + & {
    margin-top: $unit-small;
  }
}



.c-filter__value {
  width:        24px;
  height:       24px;
  margin-right: 6px;
}



.c-filter-hide {
  display: none;
}




@include mq($from: tablet) {

  .c-filter-wrapper {
    flex-direction: row;
  }

  .c-filter + .c-filter {
    margin-top:  0;
    margin-left: $unit-large;
  }

}

a {
  font-weight: 700;
  color:       $color-primary;

  &,
  &:link,
  &:visited {
    text-decoration: none;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}



.heading {
  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}


.link-to-index {
  display: block;
  text-align: center;
  //padding-top: $unit-small;
  padding-bottom: $unit;
}


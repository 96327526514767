.c-prevnext {
  margin-bottom: $unit;
  @include clearfix();
}


.c-prevnext__link {
  width: 50%;
  color: $color-dark;
  a {
    font-weight: 400;
  }
}


.c-prevnext__link--prev {
  float:         left;
  padding-right: $unit-small;

  &:before {
    @extend %arrow-left;
  }
}


.c-prevnext__link--next {
  float:        right;
  text-align:   right;
  padding-left: $unit-small;

  &:after {
    @extend %arrow-right;
  }
}


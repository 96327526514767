$color-youtube-r: 212;
$color-youtube-g: 212;
$color-youtube-b: 212;
//$color-youtube-r: 196;
//$color-youtube-g: 48;
//$color-youtube-b: 43;

.c-video {
  margin:    $unit-large auto;
  max-width: 560px;
}



.c-video__video {
  position:       relative;
  padding-bottom: 56.25%;
  height:         0;
  overflow:       hidden;

  iframe {
    position: absolute;
    top:      0;
    left:     0;
    width:    100%;
    height:   100%;
    border:   0;
  }
}



.c-videolink {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $unit-small;
  margin:  $unit-small auto;
  padding-left: $unit-small;
  padding-right: $unit-small;
  //transition: background 150ms ease-in;
  //border: 1px solid rgba($color-youtube-r,$color-youtube-g,$color-youtube-b,0.33);
  //background: rgb($color-youtube-r,$color-youtube-g,$color-youtube-b);
  //background: linear-gradient(
  //                0deg,
  //                rgba($color-youtube-r,$color-youtube-g,$color-youtube-b,0.5) 0%,
  //                rgba($color-youtube-r,$color-youtube-g,$color-youtube-b,0.16) 100%
  //            );
  > img {
    width: 128px;
    max-width:160px;
    border: 1px solid rgba($color-youtube-r,$color-youtube-g,$color-youtube-b,0.5);
  }
  &:hover,
  &:focus {
    text-decoration: none;
    background: rgba($color-youtube-r,$color-youtube-g,$color-youtube-b,0.16);
  }
}



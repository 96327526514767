%separator-top {
  border-top-width: $global-border-width;
  border-top-style: $global-border-style;
}


%separator-bottom {
  border-bottom-width: $global-border-width;
  border-bottom-style: $global-border-style;
}


%separator-top--padding-small {
  padding-top: $unit-small;
}


%separator-bottom--padding-tiny {
  padding-bottom: $unit-tiny;
}

%separator-bottom--padding-small {
  padding-bottom: $unit-small;
}


%separator-top-bottom {
  @extend %separator-top, %separator-bottom;
}


%separator-top-bottom--padding-small {
  @extend %separator-top--padding-small, %separator-bottom--padding-small;
}


%separator-none {
  border:         none;
  padding-top:    0;
  padding-bottom: 0;
}


%separator--light {
  border-color: $color-light;
}


%separator--medium {
  border-color: $color-medium;
}


%separator--dark {
  border-color: $color-dark;
}


%separator-basic {
  @extend %separator-bottom, %separator--light;
}


%separator-basic--small {
  padding-bottom: $unit-small;
  margin-bottom:  $unit-small;
  @extend %separator-basic;
}


%separator-basic--normal {
  padding-bottom: $unit;
  margin-bottom:  $unit;
  @extend %separator-basic;
}


%separator-basic--large {
  padding-bottom: $unit-large;
  margin-bottom:  $unit-large;
  @extend %separator-basic;
}


%arrow-left {
  content:       '<';
  //content:       '\25c2';
  padding-right: $unit-tiny;
}


%arrow-right {
  content:      '>';
  //content:      '\25b8';
  padding-left: $unit-tiny;
}


//%arrow-down {
  //content:     '\25be';
  //margin-left: 3px;
//}
$inuit-font-size-h1: 30px;
$inuit-font-size-h2: 20px;
$inuit-font-size-h3: 18px;
$inuit-font-size-h4: 16px;

h1, h2, h3, h4, h5, h6 {
  font-family:   $font-text;
  font-weight:   400;
  color:         $color-secondary;
  margin-bottom: $unit-small;
}


h1 {
  text-align: center;
}


h2 {
}

//.heading-margin--none {
//  margin-bottom: 0;
//}
//
//.heading-margin--tiny {
//  margin-bottom: $unit-tiny;
//}

//.heading-margin--small {
//  margin-bottom: $unit-small;
//}

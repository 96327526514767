.c-page-foot {
  padding-top:    $unit;
  padding-bottom: $unit-huge;
  text-align:     center;
}





.c-page-foot--dark {
  background-color: $color-black;
}



.c-footer-nav__list {
  margin-bottom: 0;
}





.c-page-foot__copyright,
.c-footer-nav__link {
  color:       $color-light;
  line-height: 2rem;
  font-weight: 400;
}



.c-footer-nav__item + .c-footer-nav__item {
  &:before {
    content:      '|';
    padding-left: $unit-tiny;
    margin-right: $unit-tiny;
    color:       $color-light;
  }
}

.wrapper {
  padding-left: $unit-small;
  padding-right: $unit-small;
  position: relative;
}





@include mq($from: tablet) {
  .wrapper {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}



//
//
//@include mq($from: tablet) {
//  .wrapper {
//    max-width: 640px;
//    margin-left: auto;
//    margin-right: auto;
//    padding-left: 0;
//    padding-right: 0;
//  }
//}
.o-video__wrapper {
  margin-block: 2rem;
  text-align:    center;
}

.o-video__heading {
  margin-top: 2rem;
}

.o-video__poster-wrapper {
  display:         flex;
  justify-content: center;
  align-items:     center;
  position:        relative;
  //box-shadow: 1px 1px 5px rgba(#000, .5);

  &:hover,
  &:focus {
    cursor: pointer;

    > .o-video__play > svg {
      //fill: white;
      filter:     drop-shadow(0 0 7px rgba($color-black, 1));
    }

    //.o-video__poster {
    //filter: blur(0) brightness(.9);
    //}
  }
}

.o-video__poster {
  width:         100%;
  height:        auto;
  aspect-ratio:  16/9;
  z-index:       9;
  border:        2px solid rgba($color-medium, .6);
  border-radius: 13px;
  //filter:       blur(3px);
  //filter:       blur(5px) drop-shadow(1px 1px 3px rgba($color-dark,.66));
  //filter:       brightness(.9);
  //filter:       blur(3px) brightness(.9);
  //transition:    filter 150ms linear;
}

.o-video__play {
  position: absolute;
  z-index:  11;

  > svg {
    width:      60px;
    height:     60px;
    @include mq($from: phablet) {
      width:  100px;
      height: 100px;
    }
    fill:       $color-youtube;
    transition: filter 150ms linear;
    //animation:  pulse 1250ms infinite alternate;
  }
}

//@keyframes pulse {
//  0% {
//    transform: scale(1);
//  }
//  100% {
//    transform: scale(1.25);
//  }
//}

.o-video__disclaimer {
  display:          inline-block;
  padding:          3px 6px;
  font-size:        12px;
  font-weight:      400;
  background-color: rgba($color-black, .66);
  position:         absolute;
  bottom:           .5rem;
  z-index:          11;
  border-radius:    3px;
}

.o-video__disclaimer,
.o-video__disclaimer a {
  color:       rgba($color-light, .85);
  font-weight: 400;
}

.o-video__disclaimer a {
  text-decoration: underline !important;
}

.o-video__link {
  display: inline-block;
  margin:  1.5rem;
}

.o-video__overlay {
  display:          flex;
  justify-content:  center;
  align-items:      center;
  position:         fixed;
  inset:            0;
  isolation: isolate;
  z-index:          9999;
  background-color: rgba(#000, .9);
}

.o-video__modal {
  box-shadow:   0 0 3px rgba($color-black, 1);
  width:        100%;
  @include mq($from: tablet) {
    width: 90%;
  }
  @include mq($from: desktop) {
    width: 75%;
  }
  height:       auto;
  aspect-ratio: 16/9;
  line-height:  0;
}

.o-video__embed {
  width:  100%;
  height: 100%;
  border: none;
}

.o-video__close {
  position:    fixed;
  top:         $unit;
  right:       $unit;
  width:       44px;
  height:      44px;
  color:       $color-light;
  //text-shadow: 0 0 5px $color-black;
  //transition:  color 150ms linear;
    cursor: pointer;
}

[x-cloak] {
  display: none !important;
}
@import "settings/settings.config";
@import "~inuitcss/settings/settings.core";
@import "settings/settings.global";
@import "settings/settings.colors";
@import "settings/settings.fonts";
@import "~inuitcss/tools/tools.font-size";
@import "~inuitcss/tools/tools.clearfix";
@import "~inuitcss/tools/tools.hidden";
@import "~sass-mq/mq";
@import "tools/tools.aliases";
@import "tools/tools.clearfix";
@import "tools/tools.extendables";

@import "~inuitcss/generic/generic.box-sizing";
@import "~inuitcss/generic/generic.normalize";
@import "~inuitcss/generic/generic.reset";
@import "~inuitcss/generic/generic.shared";

main {
  position: relative;
  background-color: #f9f9f9;
}

header {
  padding-top: $unit-tiny;
  padding-bottom: $unit-tiny;
  margin-bottom: $unit;
  background-color: $color-light;
  > h1 {
    margin-bottom: calc($unit-tiny/2);
  }
}

@import "objects/objects.wrapper";
@import "elements/elements.ads";
@import "components/components.jumplinks";
@import "components/components.video";
@import "components/components.video-overlay";

